import { FC } from "react";

const Custom404: FC = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center text-2xl font-bold">
      404 - Page Not Found
    </div>
  );
};

export default Custom404;
